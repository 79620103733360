import React from 'react';
import ForgotForm2 from '../components/Forgotform2/Forgotform2';

const Forgot_ResetPassword = () => {
    return (
        <div>
            <ForgotForm2/>
        </div>
    );
};

export default Forgot_ResetPassword;