import React from 'react';
import ForgetVerify from '../components/ForgetVerify/ForgetVerify';

const Forgot_Requestpassword = () => {
    return (
        <div>
            <ForgetVerify/>
        </div>
    );
};

export default Forgot_Requestpassword;