import React from 'react'
import VerifyForm from '../components/verify/VerifyForm'



const Verify = () => {

  return (
     <VerifyForm />
  )
}

export default Verify