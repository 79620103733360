import React from 'react';
import Forgotform1 from '../components/Forgotform1/Forgotform1';

const ForgotPassword = () => {
    return (
        <div>
            <Forgotform1/>
        </div>
    );
};

export default ForgotPassword;