export const translationsEn = {
    brand: "Mind School",
    course: "Course",
    bookAnAppoinment: "Book an apointment",
    live: "live",
    group: "Group",
    store: "Store",
    login: "Login",
    signUp: "Sign up",
    myAccount: "My account",
    logout: "Logout",
    profile: "Profile",
    myCourses: "My Courses",
    paymentHistory: "Payment History",
    myFeedbacks: "My Feedbacks",
    deactivateAccount: "Deactivate Account",
    or: "Or",
    login_with_email:"Login with Phone Number",
    login_with_gmail:"Login with Gmail",
    login_with_facebook:"Login with Facebook",
    forgot_password:"Forgot password?",
    no_account:"Don't have an account? Sign Up",
    email:"Email",
    password:"Password",
    quiet_the_mind:"Quiet the mind and the soul will speak",
    when_meditation_mastered:"When meditation is mastered, the mind is unwavering like the flame of a candle in a windless place",
    sign_up_now:"Sign Up Now",
    our_courses:"Our Courses",
    bundle_courses:"Bundle Courses",
    see_all:"See All",
    buy:"Buy",
    course_details:"Course Details",
    instructor_details:"Instructor Details",
    meet_our_instructors:"Meet Our Instructors",
    our_achievements:"Our Achievements",
    teachers:"Instructors",
    videos:"Courses",
    users:"Users",
    subscribers:"Subcribers",
    why_love_mindschool:"Why people love Mind School",
    download_app:"Download our Mobile App",
    to_take_care_of_your_health:"to take care of your mental health",
    selected:"Selected",
    about:"About",
    contact:"Contact",
    home:"Home",
    selected_courses:"Selected Courses",
    total:"Total",
    proceed_payment:"Proceed to Payment",
    subscribe:"Subscribe",
    to_get_all_updates:"to get all the updates from Mind School",
    corporates:"Corporates",
    content:"Contents",
    sign_up:"Sign Up",
    sign_up_with_google:"SIGN UP WITH GOOGLE",
    sign_up_with_facebook:"SIGN UP WITH FACEBOOK",
    sign_up_with_email:"SIGN UP WITH EMAIL",
    full_name:"Full Name",
    already_have_an_acc:"Already have an account? Login here",
    phone:"Phone Number",
    back:"Back",
    next:"Next",
    total_lecture:"Total Lecture",
    course_length:"Course Length",
    course_description:"Course Description",
    course_price:"Course Price",
    hours:"Hours",
    lessons:"lessons",
    dashboard:"Dashboard",
    about_us_intro:"where you learn how to live a satisfying, miraculous and blissful life",
    about_us1:"A healthy mind is the mantra of a healthy life, healthy physic, and healthy relationship. Mental Health is at top concerns in present era and improving the quality of our life experience is pre-eminent. Mind School is the platform where we aim to teach and train people how to develop your relationship, health, career, mindset and mental wellbeing by illuminating on your own inner intellectual strength and capacity. Here you can learn the prime lessons of your life that matters the most.",
    about_us2:"We desire to bring joy, love and fulfilment in people’s life by sharing this unique gift brought forward by our incredibly talented mind trainers. With the ease of access to internet and technological enhancement, backed by cutting edge IT solutions, we present contents and courses through this powerful platform that can transform you radically. We welcome you to grab this life changing opportunity and surround yourself with positive affirmation.",
    about_us:"About Us",
    mind:"Mind",
    school:"School",
    powerful:"powerful",
    platform:"platform",
    a:"A",
    validity:"Validity",
    days120:"120 Days",
    days180:"180 Days",
    refer:"Refer to your friends",
    refer_button:"Refer",
    continue_from_mobile:"Continue From Mobile",
    bundle_details:"Bundle Details"
  };
  
export const translationsBn = {
    brand: "মাইন্ড স্কুল",
    course: "কোর্স",
    bookAnAppoinment: "সাক্ষাৎকার",
    live: "লাইভ",
    group: "গ্রুপ",
    store: "স্টোর",
    login: "লগইন",
    signUp: "সাইন আপ",
    myAccount: "একাউন্ট",
    logout: "লগআউট",
    profile: "প্রোফাইল",
    myCourses: "আমার কোর্স",
    paymentHistory: "পেমেন্ট ইতিহাস",
    myFeedbacks: "আমার ফিডবেক",
    deactivateAccount: "ডিএকটিভ একাউন্ট",
    or:"অথবা",
    login_with_email:"ফোন নাম্বার​ দিয়ে লগইন করুন",
    login_with_gmail:"জিমেইল দিয়ে লগইন করুন",
    login_with_facebook:"ফেসবুক দিয়ে লগইন করুন",
    forgot_password:"পাসওয়ার্ড ভুলে গেছেন?",
    no_account:"অ্যাকাউন্ট নেই? নিবন্ধন করুন",
    email:"ইমেইল",
    password:"পাসওয়ার্ড",
    quiet_the_mind:"মন শান্ত করুন এবং দেখুন আত্মা কথা বলবে",
    when_meditation_mastered:"যখন ধ্যান আয়ত্ত করা হয়, তখন মন বাতাসহীন জায়গায় মোমবাতির শিখার মতো অটল থাকে",
    sign_up_now:"এখনই সাইন আপ করুন",
    our_courses:"আমাদের কোর্সগুলি",
    bundle_courses:"বান্ডেল কোর্সগুলি",
    see_all:"সবগুলো দেখুন",
    buy:"কিনুন",
    course_details:"কোর্স ডিটেলস",
    instructor_details:"ইন্সট্রাক্টর ডিটেলস",
    meet_our_instructors:"সমস্ত প্রশিক্ষকের সাথে পরিচিত হন",
    our_achievements:"আমাদের অর্জন",
    teachers:"শিক্ষকরা",
    videos:"ভিডিওসমূহ",
    users:"ব্যবহারকারী",
    subscribers:"সাবস্ক্রিবার্স",
    why_love_mindschool:"মানুষ কেন মাইন্ড স্কুল ভালোবাসে",
    download_app:"আমাদের মোবাইল অ্যাপ ডাউনলোড করুন",
    to_take_care_of_your_health:"আপনার মানসিক স্বাস্থ্যের যত্ন নিতে",
    selected:"নির্বাচিত",
    about:"আমাদের তথ্য",
    contact:"যোগাযোগ",
    home:"হোমপেজ",
    selected_courses:"নির্বাচিত কোর্স",
    total:"মোট",
    proceed_payment:"পেমেন্ট করুন",
    subscribe:"সাবস্ক্রাইব",
    to_get_all_updates:"করুন মাইন্ড স্কুল থেকে সমস্ত আপডেট পেতে",
    corporates:"কর্পোরেটস",
    content:"কন্টেন্ট",
    sign_up:"সাইন আপ",
    sign_up_with_google:"জিমেইল দিয়ে সাইন আপ করুন",
    sign_up_with_facebook:"ফেসবুক দিয়ে সাইন আপ করুন",
    sign_up_with_email:"ইমেইল দিয়ে সাইন আপ করুন",
    full_name:"পুরো নাম",
    already_have_an_acc:"একাউন্ট আছে? এখানে লগইন করুন",
    phone:"ফোন নম্বর",
    back:"পূর্ববর্তী",
    next:"পরবর্তী",
    total_lecture:"মোট লেকচার",
    course_length:"কোর্স দৈর্ঘ্য",
    course_description:"কোর্স বর্ণনা",
    course_price:"কোর্স মূল্য",
    hours:"ঘন্টা",
    lessons:"টি লেসন",
    dashboard:"ড্যাশবোর্ড",
    about_us_intro:"যা আপনাকে একটি সুখি, আনন্দায়ক এবং প্রফুল্ল জীবন যাপন করতে শেখাবে।",
    about_us1:"একটি সুস্থ মন আমাদের সুস্থ দেহ, সুসম্পর্ক এবং সর্বপরি একটি সুন্দর জীবনের চাবিকাঠি। বর্তমান যুগে জীবনযাত্রার মান উন্নয়নে মানসিক সুস্বাস্থ্য একটি খুবই গুরুত্বপুর্ন এবং আলোচ্য বিষয়। মাইন্ডস্কুলের মূল লক্ষ্যই হল আপনার বুদ্ধি এবং চেতনা বিকাশে সহায়তা করে আপনার ভেতরের সত্ত্বাকে জাগিয়ে তোলা। যেন আপনি সহজেই সফল ক্যারিয়ার, সুস্বাস্থ্য, মানসিক স্থিরতা এবং পারস্পরিক সুসম্পর্ক গড়ে তুলতে পারেন।",
    about_us2:"এখানে আপনি জানবেন এবং শিখবেন সে সব বিষয় যা আপনার জীবনের পথে সফলতার সাথে এগিয়ে যেতে আপরিহার্য ভূমিকা রাখবে। মাইন্ডস্কুলে আছেন স্বনামধন্য এবং প্রতিভাবান ট্রেইনার, যারা আপনার জীবনে আত্মতৃপ্তি, ভালবাসা এবং পরিপূর্ণতা খুঁজে পাবার পথ দেখাবেন। প্রযুক্তির দ্রুত প্রসার এবং ইন্টারনেটের সহজলভ্যতার পাশাপাশি সেরা ডিজিটাল সল্যুশন নিয়ে আমরা আপনাকে দিচ্ছি যুগোপযোগী বিষয়বস্তু এবং কোর্স, যার মাধ্যমে আপনি আপনার জীবনের আমূল পরিবর্তন আনতে সক্ষম হবেন। মাইন্ডস্কুলের ইতিবাচক এবং অপার সম্ভাবনাময় জগতে আপনাকে স্বাগতম।",
    about_us:"আমাদের তথ্য",
    mind:"মাইন্ড",
    school:"স্কুল",
    a:"একটি",
    powerful:"প্রগতিশীল",
    platform:"প্লাটফর্ম",
    validity:"মেয়াদ",
    days120:"১২০ দিন",
    days180:"১৮০ দিন",
    refer:"রেফার করুন আপনার বন্ধুদের​",
    refer_button:"রেফার",
    continue_from_mobile:"মোবাইল থেকে কন্টিনিউ করুন​",
    bundle_details:"বান্ডেল ডিটেলস"
  };